import React from 'react';
import Layout from '../components/layouts/main-layout';
import Container from '../components/container';
import FaqSection from '../components/faqsection';
import Seo from '../components/seo';

const FAQ = () => {
  return ( 
    <Layout>
      <Seo
        title="Häufig gestellte Fragen"
        description="Häufig gestellte Fragen zu den Kursen der Langeooger Surfschule Tidens Surfhuus."
      />
      <Container>
        <h1>Häufig gestellte Fragen</h1>
        <FaqSection sport="all"/>
      </Container>
    </Layout>
   );
}
 
export default FAQ;
